export const globalVar = {
	themes: {
		default: {
			value: 'default',
			name: 'Premium 2'
		},
		theme1: {
			value: 'theme-1',
			name: 'Free 2'
		},
		theme2: {
			value: 'theme-2',
			name: 'Premium 1'
		},
		theme3: {
			value: 'theme-3',
			name: 'Free 1'
		},
		theme4: {
			value: 'theme-4',
			name: 'Premium 4'
		},
		noneTheme: {
			value: '',
			name: 'Premium 3'
		}
	},
	themeList: [],
}

globalVar.themeList = [
	globalVar.themes.default.value,
	globalVar.themes.theme1.value,
	globalVar.themes.theme2.value,
	globalVar.themes.theme3.value,
	globalVar.themes.theme4.value
];