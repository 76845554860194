export const state = () => ({
	loading: false,
	languageStrings: {},
})

export const actions = {
	async languageStrings({ commit }) {
		commit('setLoading', true)
		try {
			await this.$axios.get('/language-strings', {
				params: {}
			}).then((response) => {
				commit('setLanguageString', response.data)
			})
			commit('setLoading', false)
			return true;
		} catch (error) {
			commit('setLoading', false)
			return false
		}
	},
}

export const mutations = {
	setLoading: (state, v) => {
		state.loading = v
	},
	setLanguageString: (state, v) => {
		state.languageStrings = v
	},
}

export const getters = {
	localeLanguage: state => language => {
		if (!language) {
			return {}
		}
		if (!state.languageStrings?.length) return {}
		const translation = state.languageStrings.map((data) => {
			return {
				code: data.code,
				text: data.text[language]
			}
		})
		return translation;
	}
}
