// State
export const state = () => ({
	// loginPopup: false,
	token: null,
	loading: false,
	totalBalanceLoading: false,
	player: {
		balance: 0,
	},
	totalBalance: '',
	totalBalanceFromSocket: null,
	profile: null,
	messages: [],
	deposits: [],
	withdrawals: [],
	// isOtpSent: false,
	// betHistory: {},
	wallets: {},
	providerWallets: [],
	// depositDetail: {},
	// betHistoryMobile: {
	// 	total: 0,
	// 	data: [],
	// 	totalPages: 0,
	// },
	affiliates: [],
	onHoldWallet: 0
})

// Actions
export const actions = {
	// Logout
	async logout({ commit, dispatch }) {
		try {
			if (this.$cookie.get('token')) {
				await this.$axios.put('/players/logout').finally(() => {
					this.$cookie.remove("token");
					dispatch('settings/toggleIsLogin', false, { root: true })
					this.$toast.success(this.getters['theme2/language-string/tl']('logoutSuccessful'));
					this.$cookie.remove("player");
					commit("setPlayer", {});
					commit("setPlayerToken", null);
					this.$router.push("/");
				})
			}
		} catch (error) {
			// this.$toast.error(error);
		}
	},

	// Profile
	async profile({ commit, dispatch }) {
		try {
			// this.commit('settings/setCancelToken', { profile: this.$axios.CancelToken.source() })
			commit('loading', true)
			await this.$axios
				.get(`/players/profile`, {
					// cancelToken: this.state.settings.cancelToken.profile.token
				})
				.then((response) => {
					commit('setPlayer', response.data)
					commit('setTotalBalance', response?.data?.balance)
					dispatch('player/totalBalanceSocket', true, { root: true })
					this.$cookie.set('player', response.data)
					commit('loading', false)
				})
				.catch(() => {
					commit('loading', false)
				})
		} catch (error) { }
	},

	// Update
	async update({ commit }, form) {
		commit('loading', true)
		await this.$axios
			.put(`/players`, form)
			.then((response) => {
				commit('loading', false)
				if (!form.language && response) {
					this.$toast.success('Player info successfully updated')
				}
			})
			.catch(() => {
				commit('loading', false)
			})
	},

	// Messages
	async messages({ commit }, params) {
		if (!this.$cookie.get('hasBank')) {
			this.$router.push(('/player/profile'))
			this.$toast.error('Please add a bank account')
			return
		}
		try {
			commit('loading', true)
			await this.$axios
				.get(`/player-messages`, { params })
				.then((response) => {
					commit('setMessages', response.data)
					commit('loading', false)
				})
				.catch(() => {
					commit('loading', false)
				})
		} catch (error) {
		}
	},

	// ? Deposits
	async deposits({ commit }, params) {
		if (!this.$cookie.get('hasBank')) {
			this.$router.push(('/player/profile'))
			this.$toast.error('Please add a bank account')
			return
		}
		try {
			commit('loading', true)
			await this.$axios
				.get(`/deposits`, { params })
				.then((response) => {
					commit('setDeposits', response.data)
					commit('loading', false)
				})
				.catch(() => {
					commit('loading', false)
				})
		} catch (error) {
		}
	},
	// async depositDetail({ commit }, id) {
	// 	try {
	// 		commit('loading', true)
	// 		await this.$axios
	// 			.get(`/deposits/${id}`)
	// 			.then((response) => {
	// 				commit('depositDetail', response.data)
	// 				commit('loading', false)
	// 			})
	// 			.catch(() => {
	// 				commit('loading', false)
	// 			})
	// 	} catch (error) {
	// 		commit('loading', false)
	// 	}
	// },

	// ? Withdrawals
	async withdrawals({ commit }, params) {
		if (!this.$cookie.get('hasBank')) {
			this.$router.push(('/player/profile'))
			this.$toast.error('Please add a bank account')
			return
		}
		try {
			commit('loading', true)
			await this.$axios
				.get(`/withdrawals`, { params })
				.then((response) => {
					commit('setWithdrawals', response.data)
					commit('loading', false)
				})
				.catch(() => {
					commit('loading', false)
				})
		} catch (error) {
		}
	},

	// ? Bet history
	// async betHistory({ commit }, data) {
	// 	const params = { ...data }
	// 	if (!this.$cookie.get('hasBank')) {
	// 		this.$router.push(('/player/profile'))
	// 		this.$toast.error('Please add a bank account')
	// 		return
	// 	}
	// 	commit('loading', true)
	// 	if (!params.start_date) params.start_date = this.$moment().format('YYYY-MM-DD') + ' 00:00:00'
	// 	if (!params.end_date) params.end_date = this.$moment().format('YYYY-MM-DD') + ' 23:59:59'

	// 	await this.$axios
	// 		.get(`/bet-history`, { params })
	// 		.then((response) => {
	// 			commit('betHistory', response.data)
	// 			commit('loading', false)
	// 		})
	// 		.catch(() => {
	// 			commit('loading', false)
	// 		})
	// },

	// ? Provider Wise Wallet
	async providerWallet({ commit, dispatch, rootState }) {
		if (!this.$cookie.get('hasBank')) {
			this.$router.push(('/player/profile'))
			this.$toast.error('Please add a bank account')
			return
		}
		commit('loading', true)
		await dispatch('games/gameProviders', null, { root: true })
		await rootState.games.gameProviders.forEach(async (provider) => {
			await this.$axios
				.get(`/players/wallets/provider/${provider.provider_code}`)
				.then((response) => {
					commit('updateProviderWallet', {
						balance: response.data.balance,
						provider_code: provider.provider_code,
						is_active: provider.is_active,
						provider_name: provider.provider_name,
						support_currency: provider.support_currency,
					})
				})
		})
		commit('loading', false)
	},
	// ? GET TOTAL BALANCE
	async totalBalance({ commit }) {
		// this.commit('settings/setCancelToken', { totalBalance: this.$axios.CancelToken.source() })
		commit('totalBalanceLoading', true)

		await this.$axios
			.get(`/players/wallets/total`, {
				// cancelToken: this.state.settings.cancelToken.totalBalance.token
			})
			.then((response) => {
				commit('setTotalBalance', response?.data?.main_wallet || response?.data?.mainWallet)
				commit('setOnHoldWallet', response?.data?.onHoldWallet)
				commit('totalBalanceLoading', false)
			})
			.catch(() => {
				commit('totalBalanceLoading', false)
			})
	},

	async transfer({ commit, dispatch }) {
		commit('loading', true)
		await this.$axios
			.put(`/players/wallets/transfer/main`)
			.then(async (response) => {
				// await dispatch('providerWallet')
				await dispatch('profile')
				commit('loading', false)
				await dispatch('totalBalance')

				// this.$toast.success(`Total Balance ${response.data.totalBalanceToTransfer}`)
			})
			.catch(() => {
				commit('loading', false)
			})
	},

	// this action is used only for load more in bet history on mobile
	// async loadMoreBetHistory({ commit }, params) {
	// 	if (!this.$cookie.get('hasBank')) {
	// 		this.$router.push(('/player/profile'))
	// 		this.$toast.error('Please add a bank account')
	// 		return
	// 	}
	// 	try {
	// 		commit('loading', true)
	// 		await this.$axios
	// 			.get(`/bet-history`, { params })
	// 			.then((response) => {
	// 				commit('setWithdrawalsMobile', {
	// 					...response.data,
	// 					search: params.search,
	// 				})
	// 				commit('loading', false)
	// 			})
	// 			.catch(() => {
	// 				commit('loading', false)
	// 			})
	// 	} catch (error) {
	// 	}
	// },

	async affiliates({ commit }) {
		commit('loading', true)
		await this.$axios
			.get(`/reference`)
			.then((response) => {
				commit('affiliates', response.data)
				commit('loading', false)
			})
			.catch(() => {
				commit('loading', false)
			})
	},
	async createAffiliates({ commit }, form) {
		commit('loading', true)
		const response = await this.$axios
			.post(`/reference/register`, form)
			.then((response) => {
				const player = this.$cookie.get('player');
				player.aff_status = true;
				this.$cookie.set('player', player)
				return true;
			})
			.catch(() => {
				return false;
			})
		commit('loading', false)
		return response;
	},

	totalBalanceSocket({ commit }, params) {
		commit('setTotalBalanceSocket', params)
	},
	async playerWalletsTransfer({ commit }, form) {
		const response = await this.$axios
			.post(`/players/wallets/transfer`, form)
			.then((response) => {
				return true;
			})
			.catch(() => {
				return false;
			})
		return response;
	},
	setHoldBalance({ commit }, val) {
		commit('setOnHoldWallet', val)
	}
}

// Mutations
export const mutations = {
	// loginPopup: (state, data) => {
	// 	state.loginPopup = data
	// },
	setPlayer: (state, data) => {
		state.player = data
	},
	setPlayerToken: (state, token) => {
		state.token = token
	},
	loading: (state, type) => {
		state.loading = type
	},
	totalBalanceLoading: (state, type) => {
		state.totalBalanceLoading = type
	},
	setMessages(state, data) {
		state.messages = data
	},
	setDeposits: (state, data) => {
		state.deposits = data
	},
	setWithdrawals: (state, data) => {
		state.withdrawals = data
	},
	// isOtpSent: (state, type) => {
	// 	state.isOtpSent = type
	// },
	// betHistory: (state, v) => {
	// 	state.betHistory = v
	// },
	wallets: (state, v) => {
		state.wallets = v
	},
	setTotalBalance: (state, v) => {
		state.totalBalance = v
		state.totalBalanceFromSocket = v
	},
	updateProviderWallet: (state, v) => {
		// 2nd-nth loading time: if providerWallets existed, check its balance first
		if (state.providerWallets.length > 0) {
			if (
				state.providerWallets.some((e) => e.provider_code === v.provider_code)
			) {
				state.providerWallets.forEach((item, index, arr) => {
					if (
						item.provider_code === v.provider_code &&
						item.balance !== v.balance
					) {
						arr[index].balance = v.balance
					}
				})
			} else if (
				state.providerWallets.some((e) => e.provider_code !== v.provider_code)
			) {
				state.providerWallets.push(v)
			}
		} else {
			// first time loading: need to add all items
			state.providerWallets.push(v)
		}
	},
	// depositDetail: (state, data) => {
	// 	state.depositDetail = data
	// },
	// setWithdrawalsMobile: (state, data) => {
	// 	if (data.search) {
	// 		state.betHistoryMobile = data
	// 		return
	// 	}
	// 	if (state.betHistoryMobile.data.length === 0) {
	// 		state.betHistoryMobile = data
	// 	} else {
	// 		const cloneBetHistoryData = [...state.betHistoryMobile.data]
	// 		const newData = cloneBetHistoryData.concat(data.data)
	// 		state.betHistoryMobile.data = newData
	// 		state.betHistoryMobile.total = data.total
	// 		state.betHistoryMobile.totalPages = data.totalPages
	// 	}
	// },
	setTotalBalanceSocket: (state, data) => {
		state.totalBalanceFromSocket = data.total_balance
	},
	affiliates: (state, data) => {
		state.affiliates = data
	},
	setOnHoldWallet: (state, data) => {
		state.onHoldWallet = data
	}
}

// Getters
export const getters = {}