// State
export const state = () => ({
	paymentTypes: [],
	exchangeRate: {},
	checkDepositAndWithdraw: {},
})

// Actions
export const actions = {
	// Payment types
	async paymentTypes({ commit }) {
		if (!this.$cookie.get('hasBank')) {
			this.$router.push(('/player/profile'))
			this.$toast.error('Please add a bank account')
			return
		}
		await this.$axios
			.get('/payment-types')
			.then((response) => {
				commit('paymentTypes', response.data)
			})
			.catch(() => false)
	},

	// Exchange rate
	async exchangeRate({ commit }, code) {
		await this.$axios
			.get('/exchange-rate', {
				params: {
					from_c: code,
					to_c: this.$cookie.get('player').currency,
				},
			})
			.then((response) => {
				commit('exchangeRate', response.data)
			})
			.catch(() => false)
	},

	// New Concept Exchange rate
	async exchangeRateManual({ commit }, code) {
		await this.$axios
			.get('/exchange-rate', {
				params: code,
			})
			.then((response) => {
				commit('exchangeRate', response.data)
			})
			.catch(() => false)
	},
	async checkDepositAndWithdraw({ commit }, params) {
		const response = await this.$axios
			.get('/currency/verify-amount', {
				params,
			})
			.then((response) => {
				commit('checkDepositAndWithdraw', response.data)
				return response.data;
			})
			.catch(() => { return false })
		return response;
	},
}

// Mutations
export const mutations = {
	paymentTypes: (state, v) => {
		state.paymentTypes = v
	},
	exchangeRate: (state, data) => {
		state.exchangeRate = data
	},
	checkDepositAndWithdraw: (state, v) => {
		if (!v) {
			v = {}
		}
		state.checkDepositAndWithdraw = v
	},
}

// Getters
export const getters = {
	providersByPaymentType: (state) => (paymentTypeCode) => {
		const paymentType = state.paymentTypes.find(
			(o) => o.code === paymentTypeCode
		)
		if (paymentType) {
			return paymentType.providers
		} else {
			return []
		}
	},
	providerByPaymentType: (state, getters) => (paymentTypeCode, providerId) => {
		const paymentType = getters.providersByPaymentType(paymentTypeCode)
		const provider = paymentType.find((o) => o.id === providerId)
		if (provider) {
			return provider
		} else {
			return {}
		}
	},
}
