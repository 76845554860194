// State
export const state = () => ({
	tempData: {},
	thirdPartyPayments: [],
	depositFlow: {},
	checkExist: {},
	checkDeposit: false,
	companyCryptoWallet: []
})

// Actions
export const actions = {
	async create({ commit }, data) {
		const form = { ...data }
		const response = await this.$axios
			.post(`/deposits`, form)
			.then((response) => {
				if (response) {
					commit('setTempData', response.data)
					return response.data
				}
			})
			.catch(() => {
				return false;
			})
		return response;
	},
	async confirm({ commit }, id) {
		const response = await this.$axios
			.put(`/deposits/${id}`, {})
			.then((response) => {
				commit("checkDeposit", true)
				return true
			})
			.catch(() => {
				return false
			})
		return response;
	},
	async revoke({ commit }, id) {
		const response = await this.$axios
			.put(`/deposits/revoke/${id}`, {})
			.then((response) => {
				commit("checkDeposit", false)
				return true
			})
			.catch(() => {
				return false
			})
		return response;
	},
	async checkExist({ commit }, _) {
		const response = await this.$axios
			.get(`/deposits/check/is-exists`)
			.then((response) => {
				commit("checkExist", response.data)
				return response.data
			})
			.catch(() => {
				return null;
			})
		return response
	},
	async getThirdPartyPayments({ commit }, _) {
		await this.$axios.get('third-party-payments/providers')
			.then((response) => {
				commit('setThirdPartyPayments', response.data)
			})
			.catch(() => {

			})
	},
	async depositFlow({ commit }, currency) {
		await this.$axios.get(`website-setting/deposit-flow/${currency}`)
			.then((response) => {
				commit('depositFlow', response.data)
				// commit('depositFlow', {
				// 	deposit_type: 2,
				// 	is_bank_transfer_enabled: true,
				// 	is_third_party_payment_enabled: true
				// })
			})
			.catch(() => {

			})
	},
	async depositCrypto({ commit }, data) {
		return await this.$axios.post(`deposits/crypto`, data)
			.then((response) => {
				return response.data
			}).catch((er) => {
				return false;
			})
	},
	async createDepositThirdParty({ commit }, data) {
		const windowLink = window.open()
		const response = await this.$axios.post(`deposits/third-party`, data)
			.then((response) => {
				commit('setTempData', response.data)
				windowLink.location = response.data.tp_payment_link
				return response.data
			}).catch((er) => {
				return false;
			})
		return response
	},
	async getCompanyCryptoWallet({ commit }, params) {
		const response = await this.$axios.get(`company-crypto-wallet`, { params })
			.then((response) => {
				commit('setCompanyCryptoWallet', response.data)
				return response.data
			}).catch((er) => {
				return false;
			})
		return response
	}
}

// Mutations
export const mutations = {
	setTempData: (state, data) => {
		state.tempData = data
	},
	setThirdPartyPayments: (state, data) => {
		state.thirdPartyPayments = data
	},
	depositFlow: (state, data) => {
		state.depositFlow = data;
	},
	checkExist: (state, data) => {
		state.checkExist = data
	},
	checkDeposit: (state, data) => {
		state.checkDeposit = data
	},
	setCompanyCryptoWallet: (state, data) => {
		state.companyCryptoWallet = data
	}
}

// Getters
export const getters = {}
