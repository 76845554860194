// State
export const state = () => ({
	loading: false,
})

// Actions
export const actions = {

}

// Mutations
export const mutations = {

}

// Getters
export const getters = {}
