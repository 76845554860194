// State
export const state = () => ({
	loading: false,
	page: {},
	pages: [],
})

// Actions
export const actions = {
	// Get pages
	async pages({ commit, rootGetters, state }, params) {
		commit('setLoading', true)
		await this.$axios
			.get(`/content-pages`, {
				params: {
					platform: rootGetters['settings/isMobile'] ? 'phone' : 'desktop',
					language: params.language
						? params.language
						: state.locale,
				},
			})
			.then((response) => {
				commit('setPages', response.data)
				commit('setLoading', false)
			})
			.catch(() => false)
	},

	// Get single page
	async page({ commit, rootGetters, state }, id) {
		commit('setLoading', true)
		await this.$axios
			.get(`/content-pages/code/${id}`, {
				params: {
					platform: rootGetters['settings/isMobile'] ? 'phone' : 'desktop',
					language: state.locale,
				},
			})
			.then((response) => {
				commit('setPage', response.data)
				commit('setLoading', false)
			})
			.catch(() => false)
	},
}

// Mutations
export const mutations = {
	setPages(state, data = Array) {
		state.pages = data
	},
	setPage(state, data = Array) {
		state.page = data
	},
	setLoading: (state, type) => {
		state.loading = type
	},
}

// Getters
export const getters = {
	// pages: (state) => {
	// 	return state.pages
	// },
}
